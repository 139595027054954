<script setup>
import { useForm } from '@inertiajs/vue3';
import { computed, inject } from 'vue';
import { useI18n } from 'vue-i18n';

import Button from '@/Components/Button/Button.vue';
import Table from '@/Components/Table/Table.vue';
import StaffLayout from '@/Layouts/StaffLayout.vue';

import ButtonLink from '@/Components/Button/Link.vue';
import DataCard from '@/Components/DataCard.vue';

// Components
import DataTableLine from '@/Components/DataTableLine.vue';

// Partials
import StatusBar from '@/Components/Invoice/StatusBar.vue';
import Title from '@/Components/Title/Title.vue';

// Icons
import { mapErrors } from '@/utilities';
import download from '@i/download-white.svg';
import pencil from '@i/pencil-white.svg';
import plane from '@i/plane-white.svg';

const { t } = useI18n();
const route = inject('route');

const props = defineProps({
    purchaseInvoice: Object,
});

const tableSettings = [
    { label: t('Name'), width: '35%', cell: 'Bold' },
    { label: t('Quantity'), width: '15%' },
    { label: t('Unit price'), width: '15%' },
    { label: t('VAT'), width: '15%' },
    { label: t('Total'), width: '15%', cell: 'Bold' },
];

const tableData = computed(() =>
    props.purchaseInvoice.purchase_invoice_lines.map((line) => {
        return {
            id: line.id,
            rowData: [
                line.description,
                line.quantity,
                '€ ' + line.unit_price / 100,
                line.vat_rate + '%',
                '€ ' + line.amount,
            ],
        };
    })
);

const form = useForm({
    purchase_invoice_id: props.purchaseInvoice.id,
});

const sendForm = () => {
    form.post(route('staff.open-purchase-invoices.store'), {
        preserveState: true,
        onError: (errors) => (form.errors = mapErrors(errors)),
    });
};
</script>

<template>
    <StaffLayout :title="$t('Purchase invoice')">
        <template #default>
            <Title :title="purchaseInvoice.number">
                <Button
                    :icon="plane"
                    :processing="form.processing"
                    :recentSuccessful="form.recentSuccessful"
                    @click="sendForm"
                    :text="$t('Send')"
                    v-if="purchaseInvoice.status == 'concept'"
                />

                <ButtonLink
                    pink
                    :icon="pencil"
                    :href="route('staff.purchase-invoices.edit', { id: purchaseInvoice.id }) + '/#add-line-button'"
                    :text="$t('Edit {model}', { model: 'purchase invoice' })"
                    v-if="purchaseInvoice.status == 'concept'"
                />

                <Button
                    asA
                    orange
                    :icon="download"
                    :href="route('staff.purchase-invoices.download', { id: purchaseInvoice.id })"
                    :text="$t('Download')"
                />
            </Title>

            <div class="flex items-end gap-8">
                <DataCard class="mt-6">
                    <p class="font-bold">{{ purchaseInvoice.user.display_name }}</p>
                    <p>
                        {{ purchaseInvoice.user.company.address.street_name }}
                        {{ purchaseInvoice.user.company.address.street_number
                        }}{{ purchaseInvoice.user.company.address.street_number_addition }}<br />
                        {{ purchaseInvoice.user.company.address.postcode }},
                        {{ purchaseInvoice.user.company.address.city }}
                    </p>
                </DataCard>

                <DataCard class="mt-6">
                    <p v-if="purchaseInvoice.user.phone_number" class="m-0">{{ purchaseInvoice.user.phone_number }}</p>
                    <p v-if="purchaseInvoice.user.email" class="m-0">
                        {{ purchaseInvoice.user.email }}
                    </p>
                </DataCard>
            </div>

            <StatusBar :invoice="purchaseInvoice" />

            <Table :simpleLayout="true" :tableSettings="tableSettings" :data="tableData" />

            <div class="self-end w-2/5 mt-6">
                <DataTableLine>
                    <p>{{ $t('Total ex. VAT') }}</p>
                    <span class="font-bold"> € {{ purchaseInvoice.total }}</span>
                </DataTableLine>
                <DataTableLine>
                    <p>{{ $t('VAT') }}</p>
                    <span class="font-bold"> € {{ purchaseInvoice.vat }}</span>
                </DataTableLine>
                <DataTableLine>
                    <p>{{ $t('Total incl. VAT') }}</p>
                    <span class="font-bold"> € {{ purchaseInvoice.total_including_vat }}</span>
                </DataTableLine>
            </div>
        </template>
    </StaffLayout>
</template>
